import axiosClient from '../axiosClient';

const getRequestTypes = async () => {
    try {
        const response = await axiosClient.get(`/request-types`);

        return response.data;
    } catch (error) {
        throw error;
    }
}

const createRequest = async (payload) => {
    const fd = new FormData();

    Object.keys(payload).forEach(item => {
      fd.append(item, payload[item]);
    });

    try {
        const response = await axiosClient.post(
            `/request`,
            fd,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const requestService = {
    getRequestTypes,
    createRequest,
}
