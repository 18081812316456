import React, { useEffect, useState, useCallback, useRef } from 'react';
import styles from './Form.module.scss';
import FileDrop from '../FileDrop/FileDrop';
import { requestService } from '../../features/request/requestService';

function Form() {
    const issueSelectRef = useRef(null);
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);
    const [requests, setRequests] = useState([]);
    const [formData, setFormData] = useState({
        requestTypeId: '',
        summary: '',
        description: '',
        files: [],
        userEmail: window.localStorage.getItem('thd_user_email') || '',
        appName: '',
    });
    const [linkOnIssue, setLinkOnIssue] = useState([]);
    const [maskedEmail, setMaskedEmail] = useState('');
    const [isMasked, setIsMasked] = useState(!!window.localStorage.getItem('thd_user_email'));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (name === 'userEmail') {
            setIsMasked(false);
            setMaskedEmail(maskEmail(value));
        }
    };

    const handleFileChange = useCallback((value) => {
        setFormData({
            ...formData,
            files: [value]
        });
    }, [formData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sending) {
            return;
        }

        setSending(true);
        window.localStorage.setItem('thd_user_email', formData.userEmail);

        const payload = {
            ...formData,
            requestTypeId: formData.requestTypeId || issueSelectRef.current.value,
            userEmail: window.localStorage.getItem('thd_user_email')
        };

        try {
            const { data } = await requestService.createRequest(payload);
            setLinkOnIssue(`https://tetheree.atlassian.net/browse/${data.ticketNumber}`);
            setSent(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSending(false);
        }
    };

    const fetch = async () => {
        try {
            const { data } = await requestService.getRequestTypes();

            window.parent.postMessage({ action: "getTitle" }, "*");
            window.addEventListener("message", (event) => {
                if (event.data && event.data.title) {
                    setFormData({
                        ...formData,
                        appName: event.data.title
                    });
                }
            });

            setRequests(data || []);

            if (requests.length > 0) {
                setTimeout(() => {
                    setFormData({
                        ...formData,
                        requestTypeId: requests[0].id
                    });
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetch();
        if (formData.userEmail) {
            setMaskedEmail(maskEmail(formData.userEmail));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const maskEmail = (email) => {
        if (!email) return '';
        const [name, domain] = email.split('@');
        const [domainName, domainExtension] = domain ? domain.split('.') : ['', ''];
        if (name.length <= 2 && domainName.length <= 2) {
            return `${name[0]}*****@${domainName[0]}*****.${domainExtension}`;
        }
        return `${name[0]}*****${name[name.length - 1]}@${domainName[0]}*****.${domainExtension}`;
    };

    const handleEmailFocus = () => {
        if (isMasked) {
            setFormData({ ...formData, userEmail: '' });
            setIsMasked(false);
        }
    };

    return (
        <div className={styles.WrapperForm}>
            {!sent ? (
                <form onSubmit={handleSubmit}>
                    <a
                        className={styles.allRequests}
                        target='_blank'
                        href='https://tetheree.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&statuses=open' rel="noreferrer"
                    >
                        See all my requests
                    </a>

                    <div className={styles.wInput}>
                        <div className={styles.label}>Issue Type<sup>*</sup></div>
                        <select
                            ref={issueSelectRef}
                            placeholder='Select issue type'
                            name='requestTypeId'
                            value={formData.requestTypeId}
                            onChange={handleChange}
                            required
                        >
                            {requests.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.wInput}>
                        <div className={styles.label}>Summary<sup>*</sup></div>
                        <input
                            type='text'
                            name='summary'
                            value={formData.summary}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.wInput}>
                        <div className={styles.label}>Description</div>
                        <textarea
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            rows={4}
                        ></textarea>
                    </div>
                    <div className={styles.wInput}>
                        <div className={styles.label}>Attach any relevant file</div>
                        <FileDrop onFileChange={handleFileChange} />
                    </div>
                    <div className={styles.wInput}>
                        <div className={styles.label}>Your contact e-mail<sup>*</sup></div>
                        <input
                            type={isMasked ? 'text' : 'email'}
                            name='userEmail'
                            value={isMasked ? maskedEmail : formData.userEmail}
                            onChange={handleChange}
                            onFocus={handleEmailFocus}
                            required
                        />
                    </div>
                    <button className={styles.btn} type='submit' disabled={sending}>{sending ? 'Sending' : 'Send'}</button>
                </form>
            ) : (
                <div className={styles.sent}>
                    <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.3344 0.750183H5.66537C2.64437 0.750183 0.750366 2.88918 0.750366 5.91618V14.0842C0.750366 17.1112 2.63537 19.2502 5.66537 19.2502H14.3334C17.3644 19.2502 19.2504 17.1112 19.2504 14.0842V5.91618C19.2504 2.88918 17.3644 0.750183 14.3344 0.750183Z" stroke="#23c748" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.43982 10.0002L8.81382 12.3732L13.5598 7.6272" stroke="#23c748" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <p>
                        Your request has been sent successfully
                        <br /><br />
                        Here is the <a href={linkOnIssue} target='_blank' rel="noreferrer">link</a>
                        <br /><br />
                        <a
                            target='_blank'
                            href='https://tetheree.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&statuses=open' rel="noreferrer"
                        >
                            See all my requests
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
}

export default Form;
