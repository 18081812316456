import React, { useCallback, useState, useRef, useEffect } from 'react';

import styles from './FileDrop.module.scss';

function FileDrop ({
    maxFileSize = 5242880,
    onFileChange,
}){
    const [file, setFile] = useState(null);
    const [dragOver, setDragOver] = useState(false);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        const handleCustomEvent = () => {
            fileInputRef.current.click();
        };

        window.addEventListener('triggerFileInputClick', handleCustomEvent);

        return () => {
            window.removeEventListener('triggerFileInputClick', handleCustomEvent);
        };
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        if (onFileChange && file.size > maxFileSize) {
            onFileChange(null);
        }

        if (file.size > maxFileSize) {
            setFile(null);
            setError(`File is too large. Maximum size is ${(maxFileSize / 1024 / 1024)}MB.`);
            return;
        }

        setFile(file);
        setError('');

        if (onFileChange) {
            onFileChange(file);
        }

    }, [onFileChange, maxFileSize]);

    const onDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const onDropAccepted = (e) => {
        e.preventDefault();
        setDragOver(false);
        onDrop(e.dataTransfer.files);
    };

    const onButtonClick = () => {
        fileInputRef.current.click();
    };

    const onFileInputChange = (e) => {
        onDrop(e.target.files);
    };

    const activeStyle = {
        borderColor: 'var(--main-blue-color)',
        backgroundColor: 'rgba(62, 144, 240, 0.12)',
    };

    const errorStyle = {
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.12)',
    };

    return (
        <div className={styles.wrapperDropFile}>
            <div
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDropAccepted}
                className={styles.dropFile}
                style={{
                ...(dragOver || file
                    ? activeStyle
                    : error && errorStyle),
                }}
            >
                <input
                    ref={fileInputRef}
                    type='file'
                    style={{ display: 'none' }}
                    onChange={onFileInputChange}
                />

                {error && (
                    <div className={styles.textInfo}>
                        <p>{error}</p>
                        <button type='button' onClick={onButtonClick}>Please choose another file</button>
                    </div>
                )}

                {!error && file && (
                    <div className={styles.fileName}>
                        Selected file: {file.name}
                    </div>
                )}

                {!error && !file && (
                    <div className={styles.textInfo}>
                        <div className={styles.info}>
                            <p>Drag and Drop file here or</p>
                            <button type='button' onClick={onButtonClick}>Choose file</button>
                        </div>
                        <div className={styles.additionalInfo}>
                            <div className={styles.fileSize}>Maximum size: {`${maxFileSize / 1024 / 1024}MB`}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileDrop;
