import React from 'react';

import styles from './Wrapper.module.scss';
import Header from '../Header/Header';
import Form from '../Form/Form';

function Wrapper({onCancel}) {
    return (
        <div className={styles.Wrapper}>
            <Header text='Tetheree Helpdesk' onCancel={onCancel} />
            <Form />
        </div>
    );
}

export default Wrapper;
