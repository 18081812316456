import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import OpenButton from './components/OpenButton/OpenButton';

import './app.css';
import Wrapper from './components/Wrapper/Wrapper';

function App() {
  const [showDialog, setShowDialog] = useState('closed'); // closed, minimized, open

  const openDialog = () => {
    window.parent.postMessage({ action: 'openHelpdeskApp' }, '*');
    setShowDialog('open');
  }

  const cancelDialog = (mode = 'closed') => {
    window.parent.postMessage({ action: 'cancelHelpdeskApp' }, '*');
    setShowDialog(mode);
  }

  const chatClass = showDialog === 'minimized' ? 'minimized' : showDialog === 'open' ? 'open' : 'closed';

  return (
    <>
      <CSSTransition
        in={showDialog !== 'closed'}
        unmountOnExit={showDialog === 'closed'}
        timeout={0}
      >
        <div className={`chat-container ${chatClass}`}>
          <Wrapper onCancel={cancelDialog} />
        </div>
      </CSSTransition>
      {(showDialog === 'closed' || showDialog === 'minimized') && (
        <OpenButton mode={showDialog} onClick={openDialog} />
      )}
    </>
  );
}

export default App;
