import React from 'react';
import styles from './OpenButton.module.scss';

function OpenButton({onClick, mode}) {
    return (
        <button
            className={styles.OpenButton}
            type="button"
            onClick={onClick}
        >
            {mode === 'minimized' && <div className={styles.live}></div>}
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="8" fill="#36AAE1"/>
                <path d="M42 14.7918V9.21205H35.6169V0H27.9096V9.21205H24V14.7943H27.9096V26.6194C27.9096 27.0123 27.9357 27.3783 27.9774 27.7344C28.298 33.4443 33.3128 38 39.4535 38L41.9583 37.9067L41.9844 30.7723H39.4561C37.3371 30.7723 35.6221 29.1539 35.6221 27.1597V18.4094H35.6195V14.7918H42Z" fill="white"/>
            </svg>
        </button>
    );
}

export default OpenButton;
